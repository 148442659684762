import Link from 'next/link';
import React, { useState } from 'react';

import ElPhoneInput from '@elements/ElPhoneInput';
import { Button } from '@features/common';
import { parsePhoneNumberData } from '@features/social-connections/components/VerifyPhone/utils';
import { RequestedPhoneNumberParams } from '@services/userProfileService/types';

const EnterMobilePhoneForm = ({
  requestPhoneNumberConfirmation,
}: {
  requestPhoneNumberConfirmation: (params: RequestedPhoneNumberParams) => void;
}): JSX.Element => {
  const [[phoneDialCode = '', phoneNumberBody = '', fullPhoneNumber = ''], setPhoneData] = useState<
    Array<string>
  >([]);

  const isPhoneNumberValid = phoneNumberBody.length >= 9 && phoneNumberBody.length <= 11;

  const onNumberEnteredHandler = (): void => {
    requestPhoneNumberConfirmation({
      phoneNumberBody,
      phoneDialCode,
      fullPhoneNumber,
    });
  };

  return (
    <div className="flex flex-col">
      <main className="mb-6 md:mb-0">
        <ElPhoneInput
          masks={{ ph: '... ... ....' }}
          value={fullPhoneNumber}
          searchPlaceholder="Country, code"
          onChange={(fullPhoneNumber, countryData) => {
            if ('dialCode' in countryData) {
              const dialCode = countryData?.dialCode || '-';
              setPhoneData(parsePhoneNumberData(dialCode, fullPhoneNumber));
            }
          }}
        />
        <div className="text-sm mt-2 text-labelSecondary">
          <span>By continuing, you agree to our&nbsp;</span>
          <Link href="/static/tos.html" passHref={true} className="text-link hover:underline">
            Terms of Service
          </Link>
        </div>
      </main>
      <footer className="pt-4 pb-6">
        <Button
          variant={isPhoneNumberValid ? 'primary' : 'secondary'}
          disabled={!isPhoneNumberValid}
          className="w-full py-2.5 md:py-1.5"
          onClick={onNumberEnteredHandler}
        >
          Get the code
        </Button>
      </footer>
    </div>
  );
};

export default EnterMobilePhoneForm;
