import React, { useMemo, useState } from 'react';

import { AuthModalData, AuthModalType, RequireAuth, useToken } from '@features/auth';
import { ConnectDiscordModal, useDiscord, VerifyPhoneModal } from '@features/social-connections';

import { AuthModalContextType } from './types';
import { useLoginModal } from '../hooks/useLoginModal';

export const AuthModalContext = React.createContext<AuthModalContextType>({
  requireAuth: () => false,
  closeAuthModal: () => undefined,
  setExactAuthModal: () => undefined,
  authModal: null,
  isAuthCompleted: false,
});

export const AuthModalContextProvider = ({ children }: React.PropsWithChildren): JSX.Element => {
  const { user } = useToken();
  const { discordUser } = useDiscord();
  const isAuthCompleted = Boolean(user);
  const [activeModal, setActiveModal] = useState<AuthModalData | null>(null);

  const { openWalletConnectModal } = useLoginModal({
    onLoginFailed: activeModal?.type === AuthModalType.WALLET ? activeModal?.payload?.onLoginFailed : undefined,
    onLoginSuccess: activeModal?.type === AuthModalType.WALLET ? activeModal?.payload?.onLoginSuccess : undefined,
    source: activeModal?.type === AuthModalType.WALLET ? activeModal.payload?.source : undefined,
  });

  const requireAuth: RequireAuth = (params): boolean => {
    const {
      includePhoneVerification,
      includeDiscordVerification = true,
      onLoginSuccess,
      onLoginFailed,
      source,
    } = params || {};

    if (!user) {
      setActiveModal({ type: AuthModalType.WALLET, payload: { onLoginSuccess, onLoginFailed, source } });
      openWalletConnectModal?.();
      return false;
    }

    if (includeDiscordVerification && !discordUser) {
      setActiveModal({ type: AuthModalType.DISCORD });
      return false;
    }

    if (includePhoneVerification && !user.isPhoneConfirmed) {
      setActiveModal({ type: AuthModalType.VERIFY_PHONE });
      return false;
    }

    setActiveModal(null);
    return true;
  };

  const closeAuthModal = (): void => setActiveModal(null);

  const setExactAuthModal = (authModalType: AuthModalData): void => setActiveModal(authModalType);

  const authModal = useMemo(() => {
    if (activeModal?.type == AuthModalType.DISCORD) {
      return <ConnectDiscordModal closeModal={closeAuthModal} />;
    }

    if (activeModal?.type === AuthModalType.VERIFY_PHONE) {
      return <VerifyPhoneModal closeModal={closeAuthModal} />;
    }

    return null;
  }, [activeModal]);

  return (
    <AuthModalContext.Provider
      value={{
        isAuthCompleted,
        requireAuth,
        closeAuthModal,
        setExactAuthModal,
        authModal,
      }}
    >
      {children}
    </AuthModalContext.Provider>
  );
};
