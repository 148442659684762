import { RecaptchaVerifier } from '@firebase/auth';
import cn from 'classnames';
import React, { useState } from 'react';

import ElModal from '@elements/ElModal';
import { ModalCloseButton } from '@features/common';
import { PlaydexLogoImage } from '@features/header';
import {
  EnterPhoneNumberModalStage,
  RecaptchaModalStage,
  SuccessPhoneVerificationModalStage,
} from '@features/social-connections/components/VerifyPhone/modalStages';
import { RequestedPhoneNumberParams } from '@services/userProfileService/types';

import { BaseModalProps } from './types';

enum ModalStages {
  ENTER_PHONE,
  RECAPTCHA,
  VERIFY_SMS_CODE,
  SUCCESSFUL_PHONE_VERIFICATION,
}

export const VerifyPhoneModal = ({ closeModal }: BaseModalProps): JSX.Element => {
  const [modalStage, setModalStage] = useState(ModalStages.ENTER_PHONE);
  const [phoneNumberParams, setPhoneNumberParams] = useState<RequestedPhoneNumberParams | null>(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);

  return (
    <ElModal
      titleSectionStyles="hidden"
      className={cn(
        ' !justify-start !bg-modalWindowBgSecondary text-labelPrimary',
        'w-full max-w-full md:max-w-[400px] min-h-screen md:min-h-40 md:rounded-lg',
        'overflow-visible',
      )}
      onCloseModal={closeModal}
    >
      <header className="flex justify-between md:justify-end items-center mt-3 mb-10 md:m-0 px-3 md:px-0">
        <div className="block md:hidden">
          <PlaydexLogoImage />
        </div>
        <ModalCloseButton className="m-3" onClick={closeModal} />
      </header>
      {modalStage === ModalStages.ENTER_PHONE && (
        <EnterPhoneNumberModalStage
          goOnNextStage={params => {
            setPhoneNumberParams(params);
            setModalStage(ModalStages.RECAPTCHA);
          }}
        />
      )}
      <div className={cn(modalStage !== ModalStages.RECAPTCHA && 'hidden')}>
        <RecaptchaModalStage
          goOnNextStage={() => {
            setModalStage(ModalStages.VERIFY_SMS_CODE);
          }}
          onRecaptchaChange={setRecaptchaVerifier}
        />
      </div>
      {modalStage === ModalStages.VERIFY_SMS_CODE && <div></div>}
      {modalStage === ModalStages.SUCCESSFUL_PHONE_VERIFICATION && (
        <SuccessPhoneVerificationModalStage closeModal={closeModal} />
      )}
    </ElModal>
  );
};
