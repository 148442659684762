import { MediaGallerySlice } from '@globalTypes/prismic/documents';

export const checkIsVideo = (item: MediaGallerySlice['items'][number]): boolean => Object.keys(item.video).length > 0;

/**
 * There are two types of item: image and video. Video is only valid if it's a YouTube video
 */
export const checkIsItemValid = (item: MediaGallerySlice['items'][number]): boolean => {
  const isVideo = checkIsVideo(item);

  if (!isVideo) {
    return true;
  }

  const isYoutubeVideo = item.video.embed_url.includes('youtu.be') || item.video.embed_url.includes('youtube.com');

  return isYoutubeVideo && getYoutubeVideoId(item.video.embed_url) !== null;
};

/**
 * @example
 * // returns dQw4w9WgXcQ
 * getYoutubeVideoId('https://youtu.be/dQw4w9WgXcQ')
 * getYoutubeVideoId('https://youtube.com/watch?v=dQw4w9WgXcQ')
 */
export const getYoutubeVideoId = (videoUrl: string): string | null => {
  const url = new URL(videoUrl);
  if (url.hostname === 'youtu.be') {
    return url.pathname.slice(1);
  }

  return url.searchParams.get('v');
};
