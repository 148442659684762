import * as yup from 'yup';

import { UserExceptionType } from '@graphql/generated';
import { LinkPhoneNumberResponse } from '@services/userProfileService/types';

export const verifyCodeFormSchema = yup
  .object({
    smsCode: yup
      .string()
      .typeError('code must be a string.')
      .label('code')
      .required()
      .min(4, 'Code must be more or equal 4 symbols')
      .max(6, 'Code must be less or equal 6 symbols'),
  })
  .required();

export const getFirebaseAuthErrorMessagesByCode = (firebaseErrorCode: string): string => {
  switch (firebaseErrorCode) {
    case 'auth/quota-exceeded':
      return 'You have reached the limit on phone verification requests, please try again later';

    case 'auth/code-expired':
      return 'Your code is expired, please try resend it';

    case 'auth/invalid-verification-code':
      return 'Wrong verification code, please try another one';

    default:
      return 'Something went wrong, please try again';
  }
};

// TODO: Implement it with separate helper

export const isLinkPhoneHadUserExceptionType = (
  linkPhoneResponse: LinkPhoneNumberResponse | void,
): linkPhoneResponse is UserExceptionType =>
  Boolean(linkPhoneResponse && linkPhoneResponse['__typename'] === 'UserExceptionType');

export const parsePhoneNumberData = (dialCode: string, fullPhoneNumber: string): string[] => {
  const phoneNumberBody = dialCode ? fullPhoneNumber.slice(dialCode.length) : '-';
  const internationFullNumberFormat = fullPhoneNumber.startsWith('+') ? fullPhoneNumber : `+${fullPhoneNumber}`;

  return [dialCode, phoneNumberBody, internationFullNumberFormat];
};
