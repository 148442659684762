import cn from 'classnames';
import React, { useEffect } from 'react';

import ElBluredImage from '@elements/ElBluredImage';
import { useToken } from '@features/auth';
import FirebaseRecaptcha from '@features/auth/components/FirebaseRecaptcha';
import { Button } from '@features/common';
import EnterMobilePhoneForm from '@features/social-connections/components/VerifyPhone/EnterMobilePhoneForm';
import useAsync from '@hooks/useAsync';
import Loader from '@modules/Loader';

import { EnterMobilePhoneStageProps, RecaptchaModalStageProps, SuccessVerifyModalStageProps } from './types';

export const EnterPhoneNumberModalStage = ({ goOnNextStage }: EnterMobilePhoneStageProps): JSX.Element => {
  return (
    <>
      <main className="px-4 md:px-6">
        <section className="flex flex-col mb-4">
          <span className="mb-2 text-lg md:text-base text-labelPrimary font-medium">
            Please, verify your phone number
          </span>
          <span className=" text-base md:text-sm text-labelSecondary">
            We need to verify your phone number in order to send a notification of registration for the tournament. You
            will receive a one-time code, which you must enter in the next step
          </span>
        </section>
        <EnterMobilePhoneForm requestPhoneNumberConfirmation={goOnNextStage} />
      </main>
    </>
  );
};

export const RecaptchaModalStage = ({ goOnNextStage, onRecaptchaChange }: RecaptchaModalStageProps): JSX.Element => {
  return (
    <>
      <main className="px-4 md:px-6 flex flex-col justify-center md:items-center">
        <ElBluredImage className="w-[60px] h-[60px] mb-4" width={60} height={60} blur="xl" src="/icons/recaptcha.svg" />
        <div
          className={cn(
            'text-lg md:text-sm font-medium md:font-normal md:text-center text-labelPrimary ',
            'md:max-w-[220px] mb-6 md:mb-4',
          )}
        >
          We need to make sure that you are a human
        </div>
        <div className="mb-6 cursor-pointer w-full flex justify-center">
          <FirebaseRecaptcha
            setRecaptchaVerifier={recaptchaVerifier => {
              onRecaptchaChange(recaptchaVerifier);
            }}
            onCaptchaSubmit={submitPayoad => {
              if (submitPayoad) {
                goOnNextStage();
              }
            }}
          />
        </div>
      </main>
    </>
  );
};

export const SuccessPhoneVerificationModalStage = ({ closeModal }: SuccessVerifyModalStageProps): JSX.Element => {
  const { connect, token } = useToken();
  const [updateUserState, updateUserHandler] = useAsync(connect);

  useEffect(() => {
    if (token) {
      updateUserHandler(token);
    }
  }, [token]);

  return (
    <>
      <main className="relative px-4 md:px-6">
        <ElBluredImage
          className="w-[60px] h-[60px] mb-4"
          width={60}
          height={60}
          blur="xl"
          src="/icons/success-pulse-frame.svg"
        />
        <section className="flex flex-col mb-4">
          <span className="mb-2 text-labelPrimary font-medium md:whitespace-nowrap">
            Phone number has been successfully verified!
          </span>
          <span className="text-labelSecondary text-sm mb-2">You can continue your registration for tournament</span>
        </section>
        <section className="flex-1 md:flex-none flex flex-col justify-end mb-6">
          <Button className="w-full py-2 md:py-1.5" onClick={closeModal} disabled={updateUserState.isPending()}>
            <span className="leading-6 md:leading-5 text-base md:text-sm font-medium ">Continue</span>
          </Button>
        </section>
        {updateUserState.isPending() && (
          <div
            className={cn(
              'absolute inset-0 min-w-full',
              'min-h-screen md:min-h-[calc(100%+44px)] bg-black/50 -translate-y-[44px]',
            )}
          >
            <div className="relative mx-auto mt-[calc(50%+22px)] -translate-y-[104px]">
              <Loader />
            </div>
          </div>
        )}
      </main>
    </>
  );
};
