import * as prismic from '@prismicio/client';
import { LinkResolverFunction } from '@prismicio/helpers';
import { enableAutoPreviews } from '@prismicio/next';
import { GetServerSidePropsContext, PreviewData } from 'next';

import sm from './sm.json';

export const endpoint = sm.apiEndpoint;
export const repositoryName = prismic.getRepositoryName(endpoint);

// This function is used to build links to Next pages for Prismic documents
export const linkResolver: LinkResolverFunction = doc => {
  switch (doc.type) {
    case 'game':
      return `/games/${doc.uid}`;
    case 'tournament':
      return `/tournaments/${doc.uid}`;
    default:
      return '/';
  }
};

type PrismicClientConfig = prismic.ClientConfig & {
  previewData?: PreviewData;
  req?: GetServerSidePropsContext['req'];
};

// This factory function allows smooth preview setup
export const createClient = (config: PrismicClientConfig = {}): prismic.Client => {
  const client = prismic.createClient(endpoint, {
    ...config,
  });

  enableAutoPreviews({
    client,
    previewData: config.previewData,
  });

  return client;
};
