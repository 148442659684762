import { match } from 'ts-pattern';
import { Chain } from 'wagmi';
import {
  bsc,
  bscTestnet,
  mainnet,
  polygon,
  polygonMumbai,
  // hardhat, // uncomment in case of hardhat usage
} from 'wagmi/chains';
// import { publicProvider } from 'wagmi/providers/public'; // uncomment in case of hardhat usage

import { featureChecker } from '@services/featureChecker';

import { jsonRpcBatchProvider } from './provider';

export * from './createAutoConnectStatusRepository';

export const getAppEnvDefaultChains = (): Array<Chain> => {
  const { isPreviewAppEnv, isDevelopmentAppEnv } = {
    isPreviewAppEnv: featureChecker.isPreviewAppEnv(),
    isDevelopmentAppEnv: featureChecker.isDevelopmentAppEnv(),
  };

  if (isPreviewAppEnv || isDevelopmentAppEnv)
    return [
      bsc,
      // hardhat, // uncomment in case of hardhat usage
      bscTestnet,
    ];

  return [bsc];
};

export const chainProviderConstructors = [
  jsonRpcBatchProvider({
    rpc: chain =>
      match(chain.id)
        .with(bscTestnet.id, () => ({
          http: 'https://rpc.ankr.com/bsc_testnet_chapel/1ca4a505ecd4843e697d40eba1f040a9aa236c124e7887d3c945cf92a1b49cbf',
        }))
        .with(bsc.id, () => ({
          http: 'https://flashy-few-valley.bsc.discover.quiknode.pro/1fe80044bcaa8a9f7be6bdcfaebb9c4793cc31c4/',
        }))
        .otherwise(() => null),
  }),
  // publicProvider({ priority: 2 }), // uncomment in case of hardhat usage
];
