export const truncateWalletAddress = (value: string): string => {
  const stringValue = value.toString();
  const startLettersNumberToShow = 5;
  const endLettersNumberToShow = 3;
  const truncatedWalletAddress = `${stringValue.slice(0, startLettersNumberToShow)}...${stringValue.slice(
    -endLettersNumberToShow,
  )}`;

  return truncatedWalletAddress;
};
