import { DocumentChange } from 'firebase/firestore';

import { IFirebaseFirestoreNotification, INotification } from '../types';

export const createNotification = (changedDocument: DocumentChange<IFirebaseFirestoreNotification>): INotification => {
  // We don't need a recipient id because of it actually a user id and it is the same for every notification.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { recipientId: _recipientId, ...document } = changedDocument.doc.data();

  const notification: INotification = {
    ...document,
    expireAt: document.expireAt?.toDate(),
    createdAt: document.createdAt.toDate(),
    updatedAt: document.createdAt.toDate(),
    id: changedDocument.doc.id,
  };

  return notification;
};
