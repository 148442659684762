import { JsonSchema, RefSchema } from '@features/common';
import { TournamentExtraFieldsSchema } from '@graphql/generated';

export const pickExtraFieldValues = (
  values: Record<string, string>,
  fields: Record<string, JsonSchema | RefSchema>,
  schemaName?: TournamentExtraFieldsSchema | null,
): Record<string, string> | undefined => {
  if (!schemaName) {
    return undefined;
  }

  return Object.entries(values).reduce(
    (result, [fieldName, fieldValue]) => {
      if (fields[fieldName]) {
        return {
          ...result,
          [fieldName]: fieldValue,
        };
      }

      return result;
    },
    {
      extraFieldsSchema: schemaName,
    } as Record<string, string>,
  );
};
