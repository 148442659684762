import classNames from 'classnames';
import getConfig from 'next/config';
import Link from 'next/link';
import React, { PropsWithChildren } from 'react';

import { ReactComponent as IconDiscord } from '@public/discord.svg';
import { ReactComponent as BlogIcon } from '@public/icons/blog.svg';
import { ReactComponent as IconFacebook } from '@public/icons/facebook.svg';
import { ReactComponent as IconLinkedin } from '@public/icons/linkedin.svg';
import { ReactComponent as IconTwitter } from '@public/icons/twitter.svg';

const { publicRuntimeConfig } = getConfig();
const { DISCORD_URL, TWITTER_URL, FACEBOOK_URL, LINKED_IN_URL } = publicRuntimeConfig;

const SocialButton = ({ children, href }: { href: string } & PropsWithChildren): JSX.Element => {
  return (
    <a
      className="flex p-2 text-labelSecondary hover:text-white cursor-pointer"
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

const BlogLink = (): JSX.Element => {
  return (
    <Link
      className={classNames(
        'flex items-center my-1.5 px-3 py-1 cursor-pointer rounded',
        'text-sm text-labelSecondary hover:text-labelPrimary transition-colors gap-2',
      )}
      href="/blog"
    >
      <BlogIcon className="w-6 h-6" />
      <div>Blog</div>
    </Link>
  );
};

export const SidebarActions = (): JSX.Element => {
  return (
    <section className="mt-auto text-xs font-medium pt-4.5 pb-4 px-2 transition-colors">
      <div className="block md:hidden mb-10">
        <BlogLink />
      </div>

      <div className="flex items-center pt-2.5 px-1">
        <SocialButton href={DISCORD_URL}>
          <IconDiscord className="w-5 h-5" />
        </SocialButton>
        <SocialButton href={TWITTER_URL}>
          <IconTwitter className="w-4 h-4" />
        </SocialButton>
        <SocialButton href={FACEBOOK_URL}>
          <IconFacebook className="w-4 h-4" />
        </SocialButton>
        <SocialButton href={LINKED_IN_URL}>
          <IconLinkedin className="w-4.5 h-4.5" />
        </SocialButton>
      </div>
    </section>
  );
};
