// Generated by @wagmi/cli@0.1.14 on 9/15/2023 at 1:49:31 PM

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SocialEventRewards
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const socialEventRewardsABI = [
  { type: 'error', inputs: [], name: 'ClaimingNotAvailable' },
  {
    type: 'error',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'ERC20TransferFailed',
  },
  { type: 'error', inputs: [], name: 'InvalidAmountsLength' },
  { type: 'error', inputs: [], name: 'InvalidMerkleProof' },
  { type: 'error', inputs: [], name: 'RewardAlreadyClaimed' },
  { type: 'error', inputs: [], name: 'WrongSocialEventState' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'eventId', internalType: 'string', type: 'string', indexed: true },
      { name: 'root', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'ClaimersMerkleTreeRootSet',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [{ name: 'eventId', internalType: 'string', type: 'string', indexed: true }],
    name: 'ClaimingStopped',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'eventId', internalType: 'string', type: 'string', indexed: true },
      { name: 'isClaimingEnabled', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'ClaimingToggled',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'eventId', internalType: 'string', type: 'string', indexed: true },
      { name: 'claimer', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'erc20Addresses', internalType: 'address[]', type: 'address[]', indexed: false },
      { name: 'erc20Amounts', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
    ],
    name: 'RewardClaimed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'eventId', internalType: 'string', type: 'string', indexed: true },
      { name: 'erc20Addresses', internalType: 'address[]', type: 'address[]', indexed: false },
      { name: 'erc20Amounts', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: 'claimersMerkleTreeRoot', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'RewardsDepositReplaced',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'eventId', internalType: 'string', type: 'string', indexed: true },
      { name: 'erc20Addresses', internalType: 'address[]', type: 'address[]', indexed: false },
      { name: 'erc20Amounts', internalType: 'uint256[]', type: 'uint256[]', indexed: false },
      { name: 'merkleTreeRoot', internalType: 'bytes32', type: 'bytes32', indexed: false },
    ],
    name: 'RewardsDeposited',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'eventId', internalType: 'string', type: 'string' },
      { name: 'rewardHash', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'checkIsRewardClaimed',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'eventId', internalType: 'string', type: 'string' },
      { name: 'amounts', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'proof', internalType: 'bytes32[]', type: 'bytes32[]' },
    ],
    name: 'claim',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'eventId', internalType: 'string', type: 'string' }],
    name: 'debugResetReward',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'eventId', internalType: 'string', type: 'string' },
      { name: 'erc20Addresses', internalType: 'address[]', type: 'address[]' },
      { name: 'erc20Amounts', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'claimersMerkleTreeRoot', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'depositEventRewards',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'eventId', internalType: 'string', type: 'string' }],
    name: 'getEvent',
    outputs: [
      {
        name: '',
        internalType: 'struct SocialEventRewards.SocialEvent',
        type: 'tuple',
        components: [
          { name: 'state', internalType: 'enum SocialEventRewards.SocialEventState', type: 'uint8' },
          { name: 'isClaimingEnabled', internalType: 'bool', type: 'bool' },
          { name: 'erc20Addresses', internalType: 'address[]', type: 'address[]' },
          { name: 'erc20Amounts', internalType: 'uint256[]', type: 'uint256[]' },
          { name: 'claimersMerkleTreeRoot', internalType: 'bytes32', type: 'bytes32' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'renounceOwnership', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'eventId', internalType: 'string', type: 'string' },
      { name: 'erc20Addresses', internalType: 'address[]', type: 'address[]' },
      { name: 'erc20Amounts', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'claimersMerkleTreeRoot', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'replaceEventRewardsDeposit',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'eventId', internalType: 'string', type: 'string' },
      { name: 'isClaimingEnabled', internalType: 'bool', type: 'bool' },
    ],
    name: 'setIsClaimingEnabled',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'eventId', internalType: 'string', type: 'string' }],
    name: 'stopClaimingAndWithdrawRemains',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20ABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'name_', internalType: 'string', type: 'string' },
      { name: 'symbol_', internalType: 'string', type: 'string' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const;
