import { DocumentChange } from 'firebase/firestore';
import produce from 'immer';

import { createNotification } from '../factories';
import { IFirebaseFirestoreNotification, INotification } from '../types';

export const updateNotificationList = (
  notificationList: Array<INotification>,
  changedDocuments: Array<DocumentChange<IFirebaseFirestoreNotification>>,
): Array<INotification> =>
  produce(notificationList, draftNotificationList => {
    const addedNotificationList = changedDocuments.filter(({ type }) => type === 'added').map(createNotification);
    const addedNotificationIds = addedNotificationList.map(({ id }) => id);

    const draftNotificationIds = draftNotificationList
      .filter(({ id }) => !addedNotificationIds.includes(id))
      .map(({ id }) => id);

    changedDocuments.forEach(changedDocument => {
      const index = draftNotificationIds.indexOf(changedDocument.doc.id);

      if (index >= 0) {
        if (changedDocument.type === 'modified') {
          const modifiedNotification = createNotification(changedDocument);
          /* eslint-disable functional/immutable-data */
          draftNotificationList[index] = modifiedNotification;
        } else if (changedDocument.type === 'removed') {
          draftNotificationList.splice(index, 1);
        }
      }
    });

    draftNotificationList.unshift(...addedNotificationList);
    /* eslint-enable functional/immutable-data */
  });
