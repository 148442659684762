import { RankRewardItem } from '../types';

export const getErc20TotalAmounts = (rewards: RankRewardItem[]): Record<string, string> => {
    return rewards.reduce((acc: Record<string, string>, reward) => {
      const multiplier = typeof reward.rank === 'number' ? 1 : reward.rank.to - reward.rank.from + 1;
  
      return Object.entries(reward.erc20AmountsWithoutDecimals).reduce((newAcc, [currency, valueToAdd]) => {
        const currentValue = newAcc[currency] ?? '0';
        const newValue = parseFloat(valueToAdd) * multiplier;
        return {
          ...newAcc,
          [currency]: (parseFloat(currentValue) + newValue).toFixed(0).toString(),
        };
      }, acc);
    }, {} as Record<string, string>);
  };
