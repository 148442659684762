import { getAuth, RecaptchaVerifier } from '@firebase/auth';
import React from 'react';

import { useIsomorphicLayoutEffect } from '@features/common';
import { clientFirebaseApp } from '@services/firebase/clientFirebase';

const FirebaseRecaptcha = ({
  onCaptchaSubmit,
  setRecaptchaVerifier,
}: {
  onCaptchaSubmit?: (submitPayload: string | void) => void;
  setRecaptchaVerifier: (recaptchaVerifier: RecaptchaVerifier | null) => void;
}): JSX.Element => {
  const clientAuth = getAuth(clientFirebaseApp);

  const initCaptcha = async (): Promise<void> => {
    const recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        theme: 'dark',
        'expired-callback': () => {
          setRecaptchaVerifier(null);
        },
        callback: onCaptchaSubmit,
      },
      clientAuth,
    );

    setRecaptchaVerifier(recaptchaVerifier);

    await recaptchaVerifier.render();
  };

  useIsomorphicLayoutEffect(() => {
    initCaptcha();
  }, []);

  return (
    <div className="w-[300px] h-[74px] overflow-hidden !rounded-md">
      <div id="recaptcha-container" className="min-w-[303px] -translate-x-px -translate-y-px !rounded-md" />
    </div>
  );
};

export default FirebaseRecaptcha;
