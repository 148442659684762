import { useAccount, useBalance, useNetwork } from 'wagmi';

import { formatBalanceValue } from '../util';

export const useNativeCurrencyBalance = (): {
  data: string | undefined;
  isLoading: boolean;
  isError: boolean;
} => {
  const { chain } = useNetwork();
  const { address } = useAccount();

  const { data: balance, isLoading, isError } = useBalance({ address, chainId: chain?.id });

  return {
    data: balance ? formatBalanceValue(balance.formatted || 0) : undefined,
    isLoading,
    isError,
  };
};
