import cn from 'classnames';
import React from 'react';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ElPhoneInput = (props: PhoneInputProps): JSX.Element => {
  return (
    <ReactPhoneInput
      containerClass="rounded-md border-1 !border-fieldBorder !relative h-10 md:!h-8 !w-full"
      buttonClass={cn(
        '!rounded-xl !rounded-r-none !bg-bgSecondary !hover:bg-red-2 !border-none',
        '!w-[64px] flex justify-center !p-0',
      )}
      dropdownClass="!font-sans absolute top-[100%] !left-0 !bg-[#222330f7] max-w-[230px] !rounded-md"
      inputClass="!rounded-md !bg-fieldBg text-white !text-base md:!text-sm !h-full !pl-18 !w-full !border-none"
      country="ph"
      {...props}
    />
  );
};

export default ElPhoneInput;
